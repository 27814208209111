<template>
    <div>
        <div style="padding-bottom: 50px;">
            <div class="dashboard-search">
                <van-search v-model="spotTitle" background="#FEC919" placeholder="请输入你需要搜索的内容" shape="round" @search="onSearch" :show-action="spotTitle !== ''" @cancel="reloadData" @clear="reloadData"/>
            </div>
            <van-cell-group>
                <van-list v-model="loading" :finished="finished" @load="loadData" >
                    <van-cell  v-for="(item,index) in pageList" :key="index" @click="toUrl(item.accessUrl)">
                        <div style="display: flex;">
                            <div style="flex: 1 1 auto">
                                <div style="overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;height: 48px;">{{item.spotTitle}}</div>
                                <div style="display: flex;justify-content: space-between;color:#888;margin-top: 13px;">
                                    <div>前往查看<van-icon name="arrow" /></div>
                                    <div>{{item.createTime.substr(0,10)}}</div>
                                </div>
                            </div>
                            <div v-if="item.spotFileId" style="flex: 0 0 80px;margin-left: 16px;">
                                <van-image :src="'https://oss.ttbaoxiu.com/' + item.spotFileId + '?x-oss-process=image/resize,m_fill,w_80,h_80'" style="width: 80px;height: 80px;"></van-image>
                            </div>
                        </div>
                    </van-cell>
                    <template #finished>
                        <span v-if="pageList.length">没有更多了</span>
                    </template>
                </van-list>
                <div v-if="finished && !pageList.length" style="width: 100%;display: flex;flex-direction: column;align-items: center;color: #C2C2C2;padding-bottom: 20px;">
                    <img src="../../assets/img/qianliang/success.png" style="width: 50%;">
                    <p v-if="spotTitle">当前没有您要查找的活动或文章</p>
                    <p v-else style="padding: 0 20px;text-align: center;">当前还没有您保存的文章，请在首页将文章添加收藏，添加后文章可永久保存。</p>
                </div>
            </van-cell-group>
        </div>
        <tabbar active="collection"></tabbar>
    </div>
</template>

<script>
import Tabbar from "./tabbar";
export default {
    name: "collection",
    components: {Tabbar},
    data(){
        return{
            spotTitle:'',
            pageList:[],  // 全部
            pageNo:0,
            totalCount:0,
            totalPage:0,
            loading: false,
            finished: false,
        }
    },
    created() {
        if(!localStorage.getItem('xToken') || !localStorage.getItem('userInfo')){
            this.$router.replace('/verify')
        }
    },
    methods:{
        loadData(){
            let that = this
            // 分别查询
            if(this.totalPage > 0 && this.totalPage === this.pageNo){
                this.$toast('已经到底啦');
                return
            }
            this.pageNo += 1
            this.post('/collecton/queryPageList',{
                size: 20,
                current: this.pageNo,
                spotTitle:this.spotTitle,
            }).then(result => {
                console.log(result)
                for (let i = 0; i < result.data.result.length; i++) {
                    this.pageList.push(result.data.result[i]);
                }
                that.loading = false;
                this.totalCount = result.data.totalCount;
                this.totalPage = result.data.totalPage;
                if(this.pageNo >= this.totalPage){
                    this.finished = true;
                }
            })
        },
        reloadData(){
            this.spotTitle = '';
            this.pageList=[];
            this.pageNo=0;
            this.totalCount=0;
            this.totalPage=0;
            this.finished = false;
        },
        onSearch(){
            this.pageList=[];
            this.pageNo=0;
            this.totalCount=0;
            this.totalPage=0;
            this.finished = false;
            this.loadData()
        },
        toUrl(url){
            document.location.href = url
        },
    },
}
</script>

<style scoped>

</style>
